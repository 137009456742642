<template>
    <div>
        <div id="wangeditor" style="text-align:left; z-index: 1000"></div>
    </div>
</template>
<script>
import wangEditor from 'wangeditor'
export default {
    name: 'editorElem',
    data () {
        return {
            editor: null,
            editorContent: ''
        }
    },
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    watch: {
        // value () {
        //     this.editor.txt.html(this.value)
        // }
    },
    mounted () {
        // eslint-disable-next-line new-cap
        const editor = new wangEditor('#wangeditor')
        // 配置 onchange 回调函数，将数据同步到 vue 中
        editor.config.onchange = (newHtml) => {
            this.$emit('change', newHtml)
        }
        // 创建编辑器
        editor.create()
        this.editor = editor

        this.$nextTick(() => {
            this.editor.txt.html(this.value)
        })
    }
}
</script>
<style lang="scss">
    .w-e-text-container{
        z-index: 1000 !important;
    }
</style>
